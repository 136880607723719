import {Component, OnInit} from '@angular/core';
import {ScrollService} from "../services/scroll.service";

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent implements OnInit {

  constructor(
    public scrollService: ScrollService
  ) {
  }

  ngOnInit(): void {
  }

  scrollToPage(page: number) {
    window.scrollTo({top: this.scrollService.windowHeight * (page - 1) + (page > 1 ? 10 : 0)});
  }
}
