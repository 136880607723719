import {NgModule} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';

import {AppComponent} from './app.component';
import {GlitchTextComponent} from './glitch-text/glitch-text.component';
import {NavbarComponent} from './navbar/navbar.component';
import {SheetTechnologiesComponent} from './sheet-technologies/sheet-technologies.component';
import {SheetHomeComponent} from './sheet-home/sheet-home.component';
import {SheetTimelineComponent} from './sheet-timeline/sheet-timeline.component';
import {SheetVitaComponent} from './sheet-vita/sheet-vita.component';
import {SheetErrorComponent} from "./sheet-error/sheet-error.component";

@NgModule({
  declarations: [
    AppComponent,
    SheetHomeComponent,
    GlitchTextComponent,
    NavbarComponent,
    SheetTechnologiesComponent,
    SheetTimelineComponent,
    SheetVitaComponent,
    SheetErrorComponent
  ],
  imports: [
    BrowserModule
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule {
}
