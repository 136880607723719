import {Component, Input, OnInit} from '@angular/core';
import {ScrollService} from "../services/scroll.service";

@Component({
  selector: 'app-sheet-home',
  templateUrl: './sheet-home.component.html',
  styleUrls: ['./sheet-home.component.scss']
})
export class SheetHomeComponent {
  @Input() page = 0;

  public scrollProgress = 0;

  constructor(
    public scrollService: ScrollService
  ) {
    this.scrollService.onScroll.subscribe(() => {
      this.onScroll();
    });
  }

  private onScroll(): void {
    this.scrollProgress = this.scrollService.getScrollProgress(this.page);
  }
}
