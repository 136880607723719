import {Component} from '@angular/core';
import {ScrollService} from "./services/scroll.service";

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'esKju | Christian Witte | Dortmund | Webdesign & Programmierung';

  constructor(public scrollService: ScrollService) {
  }
}
