import {Component, Input} from '@angular/core';
import {ScrollService} from "../services/scroll.service";

@Component({
  selector: 'app-sheet-vita',
  templateUrl: './sheet-vita.component.html',
  styleUrls: ['./sheet-vita.component.scss']
})
export class SheetVitaComponent {
  @Input() page = 0;

  public scrollProgress = 0;
  public scrollProgressAbs = 0;

  constructor(
    public scrollService: ScrollService
  ) {
    this.scrollService.onScroll.subscribe(() => {
      this.onScroll();
    });
  }

  private onScroll(): void {
    this.scrollProgress = this.scrollService.getScrollProgress(this.page);
    this.scrollProgressAbs = Math.abs(this.scrollProgress);
  }
}
