import {Injectable} from '@angular/core';
import {interval, Subject} from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class ScrollService {
  public scrollOffset = 0;
  public scrollOffsetLastInterval = 0;
  public scrollThrottle = 0;
  public windowHeight = 0;
  public page = 0;
  public onScroll = new Subject();

  private minWidth = 1600;
  private minHeight = 800;
  public minResolution = true;

  constructor() {
    window.addEventListener('scroll', (event) => {
      this.trackScroll();
    });

    this.setWindowHeight();
    window.addEventListener('resize', (event) => {
      this.setWindowHeight();
    });

    interval(250)
      .subscribe(() => {
        const maxDiff = this.windowHeight;
        const diff = this.scrollOffset - this.scrollOffsetLastInterval;

        this.scrollThrottle = (diff > maxDiff ? maxDiff : diff) / maxDiff;
        this.scrollOffsetLastInterval = this.scrollOffset;
      });
  }

  trackScroll() {
    this.scrollOffset = window.scrollY;
    this.setPage();
    this.onScroll.next();
  }

  private setWindowHeight(): void {
    this.windowHeight = window.innerHeight;
    this.setPage();

    this.minResolution = window.innerHeight >= this.minHeight && window.innerWidth >= this.minWidth;
  }

  private setPage(): void {
    this.page = Math.floor((this.scrollOffset + this.windowHeight * 0.25) / this.windowHeight) + 1;
  }

  public getScrollProgress(page: number): number {
    const offset = this.scrollOffset - (page - 1) * this.windowHeight;
    return offset / this.windowHeight;
  }
}
