import {Component, Input} from '@angular/core';
import {ScrollService} from "../services/scroll.service";
import {Technology} from "../models/technology.model";

@Component({
  selector: 'app-sheet-technologies',
  templateUrl: './sheet-technologies.component.html',
  styleUrls: ['./sheet-technologies.component.scss']
})
export class SheetTechnologiesComponent {
  @Input() page = 0;

  public scrollProgress = 0;
  public scrollProgressAbs = 0;
  public detailsVisible = false;

  public activeTechnology = -1;
  public technologies: Technology[] = [
    {
      alias: 'php',
      name: 'PHP',
      description: 'Seit meinem 13. Lebensjahr begleitet mich PHP nun in Freizeit und Berufsleben. Über die Jahre durfte ich Shops, PIM, CMS, Websites, Backends für Apps und viele weitere Projekte umsetzen. Mittlerweile liegt der Focus auf der Individualprogrammierung auf Basis von Laravel.',
      time: '2001 bis heute',
      experience: 3,
      funFactor: 3
    },
    {
      alias: 'laravel',
      name: 'Laravel',
      description: '',
      time: '',
      experience: 0,
      funFactor: 0
    },
    {
      alias: 'contao',
      name: 'Contao',
      description: 'Bei diversen Klein- bis Großprojekten durfte ich Erfahrungen mit diesem CMS sammeln. Von Websites für Gastrobetriebe, Mode-Stores bis hin zu Markenhomepages von Größen wie <b>NORDSEE</b> und <b>HIT</b> inklusive getrennten Mitarbeiterbereichen gab es reichleich Abwechslung. Durch den Einsatz anderer Systeme sowie den Fokus auf Individual-Programmierung lief mir Contao nach 2017 nur noch in der Betreuung über den Weg.',
      time: '2011 bis 2017',
      experience: 3,
      funFactor: 2
    },
    {
      alias: 'symfony',
      name: 'Symfony',
      description: '',
      time: '',
      experience: 0,
      funFactor: 0
    },
    {
      alias: 'wordpress',
      name: 'Wordpress',
      description: '',
      time: '',
      experience: 0,
      funFactor: 0
    },
    {
      alias: 'node',
      name: 'NodeJS',
      description: '',
      time: '',
      experience: 0,
      funFactor: 0
    }
  ];


  constructor(
    public scrollService: ScrollService
  ) {
    this.scrollService.onScroll.subscribe(() => {
      this.onScroll();
    });
  }

  private onScroll(): void {
    this.scrollProgress = this.scrollService.getScrollProgress(this.page);
    this.scrollProgressAbs = Math.abs(this.scrollProgress);
  }

  public showDetails(index: number): void {
    // this.activeTechnology = index;
  }

  hideDetails() {
    this.activeTechnology = -1;
  }
}
